import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  width: 100%;\n  min-height: 553px;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  position: relative;\n\n  @media (min-width: ",
        ") {\n  }\n\n  @media (min-width: ",
        ") {\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  width: 100%;\n  height: 100%;\n  padding-right: 24px;\n  display: flex;\n  flex-direction: column;\n  flex: 1;\n  gap: 16px;\n  align-items: center;\n\n  div {\n    height: 77px;\n    width: 77px;\n    cursor: pointer;\n  }\n\n  @media (min-width: ",
        ") {\n  }\n\n  @media (min-width: ",
        ") {\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  width: 100%;\n  height: 100%;\n  padding-right: 32px;\n  flex: 10;\n\n  @media (min-width: ",
        ") {\n  }\n\n  @media (min-width: ",
        ") {\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        ""
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  position: absolute;\n  bottom: 80px;\n  right: 10px;\n  width: 34px;\n  height: 34px;\n  border: 1px solid ",
        ";\n  border-radius: 100%;\n  z-index: 1;\n  text-align: center;\n  line-height: 27px;\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _tagged_template_literal([
        "\n  margin: 0 auto;\n  transform: rotate(",
        ");\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  border: 1px solid ",
        ";\n  border-radius: 100%;\n  height: 36px !important;\n  width: 36px !important;\n  svg {\n    width: ",
        ";\n    height: ",
        ";\n\n    path {\n      stroke: ",
        ";\n    }\n  }\n\n  &:hover {\n    border: 1px solid ",
        ";\n    path {\n      stroke: ",
        ";\n    }\n  }\n"
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
function _templateObject6() {
    var data = _tagged_template_literal([
        "\n  border: ",
        ";\n  border-radius: 10px;\n  overflow: hidden;\n"
    ]);
    _templateObject6 = function _templateObject6() {
        return data;
    };
    return data;
}
import { breakpoints, colors } from "@vfit/shared/themes";
import styled from "styled-components";
export var GalleryImageContainer = styled.div.withConfig({
    componentId: "sc-30798226-0"
})(_templateObject(), breakpoints.tablet, breakpoints.desktop);
export var ThumbnailContainer = styled.div.withConfig({
    componentId: "sc-30798226-1"
})(_templateObject1(), breakpoints.tablet, breakpoints.desktop);
export var SelectedImageContainer = styled.div.withConfig({
    componentId: "sc-30798226-2"
})(_templateObject2(), breakpoints.tablet, breakpoints.desktop);
export var SwiperContainer = styled.div.withConfig({
    componentId: "sc-30798226-3"
})(_templateObject3());
export var IconContainer = styled.div.withConfig({
    componentId: "sc-30798226-4"
})(_templateObject4(), colors.$bebebe);
export var ArrowButton = styled.div.withConfig({
    componentId: "sc-30798226-5"
})(_templateObject5(), function(param) {
    var rotation = param.rotation;
    return "".concat(rotation, "deg");
}, function(param) {
    var color = param.color;
    return color;
}, function(param) {
    var arrowWidth = param.arrowWidth;
    return arrowWidth ? "".concat(arrowWidth, "px") : "9px";
}, function(param) {
    var arrowHeigth = param.arrowHeigth;
    return arrowHeigth ? "".concat(arrowHeigth, "px") : "16px";
}, function(param) {
    var color = param.color;
    return color;
}, colors.$262626, colors.$262626);
export var BoxImage = styled.div.withConfig({
    componentId: "sc-30798226-6"
})(_templateObject6(), function(param) {
    var selected = param.selected, color = param.color;
    return selected ? "2px solid ".concat(colors.$0096AD) : "1px solid ".concat(colors.$bebebe);
});
