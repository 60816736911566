import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  position: fixed;\n  width: 45px;\n  height: 45px;\n  background-color: #009900;\n  right: 0;\n  top: 66vh;\n  z-index: 4;\n  border-radius: 30px 0px 0px 30px;\n  padding: 9px;\n  overflow: hidden;\n  transition: width 0.5s ease;\n  white-space: nowrap;\n  cursor: pointer;\n\n  &:hover {\n    width: 220px;\n  }\n\n  @media (min-width: ",
        ") {\n    top: 46.5vh;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  position: fixed;\n  width: 28px;\n  height: 28px;\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  align-items: center;\n  margin-left: 44px;\n  color: white;\n  font-size: 14px;\n  letter-spacing: 0.5px;\n  font-weight: 700;\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
import { breakpoints } from "@vfit/shared/themes";
import styled from "styled-components";
export var StickyWhatsappComponent = styled.div.withConfig({
    componentId: "sc-cd825fad-0"
})(_templateObject(), breakpoints.desktop);
export var StickyIcon = styled.div.withConfig({
    componentId: "sc-cd825fad-1"
})(_templateObject1());
export var StickyTitle = styled.span.withConfig({
    componentId: "sc-cd825fad-2"
})(_templateObject2());
