import _sliced_to_array from "@swc/helpers/src/_sliced_to_array.mjs";
import { useMemo } from "react";
import { purify } from "@vfit/shared/themes";
export var usePrice = function(param) {
    var amount = param.amount, detail = param.detail, label = param.label, note = param.note, specs = param.specs, description = param.description;
    var ref = _sliced_to_array(purify([
        detail,
        note,
        description
    ]), 3), pDetail = ref[0], pNote = ref[1], pDescription = ref[2];
    var isOnlyAmount = useMemo(function() {
        return amount && !detail && !label && !note && !specs;
    }, [
        amount,
        detail,
        label,
        note,
        specs
    ]);
    return {
        isOnlyAmount: isOnlyAmount,
        pDetail: pDetail,
        pNote: pNote,
        pDescription: pDescription
    };
};
