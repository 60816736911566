import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  margin-bottom: 120px;\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  margin: 0 auto;\n  background-color: ",
        ";\n  max-width: 327px;\n\n  @media (min-width: ",
        ") {\n    max-width: 680px;\n  }\n\n  @media (min-width: ",
        ") {\n    max-width: ",
        ";\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  border: 1px solid ",
        ";\n  border-radius: 20px;\n  justify-content: space-between;\n  flex-direction: column-reverse;\n\n  @media (min-width: ",
        ") {\n    flex-direction: row;\n  }\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  padding: 32px 24px;\n\n  @media (min-width: ",
        ") {\n    min-height: 307px;\n    width: 296px;\n  }\n\n  @media (min-width: ",
        ") {\n    min-height: 441px;\n    width: 403px;\n  }\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  margin: 0;\n  font-family: ",
        ";\n  font-weight: 400;\n  ",
        ";\n  overflow: auto;\n  padding-bottom: ",
        ";\n\n  @media (min-width: ",
        ") {\n    ",
        "\n  }\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _tagged_template_literal([
        "\n  margin: 0;\n  font-family: ",
        ";\n  font-weight: 400;\n  ",
        "\n  color: ",
        ";\n\n  @media (min-width: ",
        ") {\n    ",
        "\n  }\n"
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
function _templateObject6() {
    var data = _tagged_template_literal([
        "\n  margin-top: 24px;\n\n  @media (min-width: ",
        ") {\n    display: block;\n  }\n\n  @media (min-width: ",
        ") {\n    display: inline-block;\n  }\n"
    ]);
    _templateObject6 = function _templateObject6() {
        return data;
    };
    return data;
}
function _templateObject7() {
    var data = _tagged_template_literal([
        "\n  margin-top: 44px;\n\n  div {\n    text-align: left;\n    margin: 0;\n    font-family: ",
        ";\n    font-weight: 400;\n    ",
        "\n    color: #666;\n\n    a {\n      color: ",
        ";\n      font-weight: 700;\n      ",
        "\n    }\n\n    a:hover {\n      color: ",
        ";\n    }\n\n    a:visited {\n      color: ",
        ";\n    }\n\n    a:active {\n      color: ",
        ";\n    }\n  }\n"
    ]);
    _templateObject7 = function _templateObject7() {
        return data;
    };
    return data;
}
function _templateObject8() {
    var data = _tagged_template_literal([
        "\n  height: 150px;\n  object-fit: ",
        ";\n\n  @media (min-width: ",
        ") {\n    height: auto;\n    width: 50%;\n  }\n\n  @media (min-width: ",
        ") {\n    min-height: 441px;\n  }\n"
    ]);
    _templateObject8 = function _templateObject8() {
        return data;
    };
    return data;
}
function _templateObject9() {
    var data = _tagged_template_literal([
        "\n  border-top-right-radius: 20px;\n  border-top-left-radius: 20px;\n  width: 100%;\n\n  @media (min-width: ",
        ") {\n    margin-left: 0;\n    border-top-right-radius: 20px;\n    border-bottom-right-radius: 20px;\n    border-top-left-radius: 0;\n  }\n"
    ]);
    _templateObject9 = function _templateObject9() {
        return data;
    };
    return data;
}
import styled from "styled-components";
import { breakpoints, colors, fonts, pxToCssFont, pxToRem } from "@vfit/shared/themes";
export var Container = styled.div.withConfig({
    componentId: "sc-346f9cd9-0"
})(_templateObject());
export var MainContainer = styled.div.withConfig({
    componentId: "sc-346f9cd9-1"
})(_templateObject1(), colors.$ffffff, breakpoints.tablet, breakpoints.desktop, pxToRem(1062));
export var CardContainer = styled.div.withConfig({
    componentId: "sc-346f9cd9-2"
})(_templateObject2(), colors.$bebebe, breakpoints.tablet);
export var DescriptionBox = styled.div.withConfig({
    componentId: "sc-346f9cd9-3"
})(_templateObject3(), breakpoints.tablet, breakpoints.desktop);
export var Title = styled.h4.withConfig({
    componentId: "sc-346f9cd9-4"
})(_templateObject4(), fonts.exbold, pxToCssFont(24, 38), pxToRem(24), breakpoints.desktop, pxToCssFont(28, 45));
export var Description = styled.p.withConfig({
    componentId: "sc-346f9cd9-5"
})(_templateObject5(), fonts.regular, pxToCssFont(18, 24), colors.$262626, breakpoints.desktop, pxToCssFont(18, 24));
export var CtaContainer = styled.div.withConfig({
    componentId: "sc-346f9cd9-6"
})(_templateObject6(), breakpoints.tablet, breakpoints.desktop);
export var Info = styled.div.withConfig({
    componentId: "sc-346f9cd9-7"
})(_templateObject7(), fonts.regular, pxToCssFont(14, 18), colors.$262626, pxToCssFont(16, 22), colors.$e60000, colors.$262626, colors.$262626);
export var ContainerImage = styled.div.withConfig({
    componentId: "sc-346f9cd9-8"
})(_templateObject8(), function(param) {
    var disableCoverImg = param.disableCoverImg;
    return disableCoverImg ? "contain" : "cover";
}, breakpoints.tablet, breakpoints.desktop);
export var ImageElement = styled.img.withConfig({
    componentId: "sc-346f9cd9-9"
})(_templateObject9(), breakpoints.tablet);
