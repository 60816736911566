import { CMS, DXL, NEXT, WEBAPI_SERVERLESS, DXL_JOURNEY_APP, AWSAPI_CONSUMER, DXLCLOUD_CONSUMER, DXL_GATEWAY_CLOUD } from "./config";
export var options = {
    DXL: DXL,
    NEXT: NEXT,
    CMS: CMS,
    WEBAPI_SERVERLESS: WEBAPI_SERVERLESS,
    DXL_JOURNEY_APP: DXL_JOURNEY_APP,
    AWSAPI_CONSUMER: AWSAPI_CONSUMER,
    DXLCLOUD_CONSUMER: DXLCLOUD_CONSUMER,
    DXL_GATEWAY_CLOUD: DXL_GATEWAY_CLOUD
};
