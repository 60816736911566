import _tagged_template_literal from "@swc/helpers/src/_tagged_template_literal.mjs";
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  align-items: flex-start;\n  justify-content: space-between;\n\n  button {\n    margin-bottom: 24px;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  width: 100%;\n\n  @media (min-width: ",
        ") {\n    width: 350px;\n  }\n"
    ]);
    _templateObject1 = function _templateObject1() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n  width: 250px;\n"
    ]);
    _templateObject2 = function _templateObject2() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n  ",
        "\n  div {\n    margin: 0;\n    line-height: 42px;\n    margin-bottom: 24px;\n  }\n\n  @media (min-width: ",
        ") {\n    ",
        "\n  }\n"
    ]);
    _templateObject3 = function _templateObject3() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n  div {\n    margin: 0;\n    line-height: 24px;\n  }\n"
    ]);
    _templateObject4 = function _templateObject4() {
        return data;
    };
    return data;
}
function _templateObject5() {
    var data = _tagged_template_literal([
        "\n  max-width: 180px;\n  margin: 24px auto;\n"
    ]);
    _templateObject5 = function _templateObject5() {
        return data;
    };
    return data;
}
function _templateObject6() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n\n  @media (min-width: ",
        ") {\n    span {\n      font-size: 24px;\n    }\n  }\n"
    ]);
    _templateObject6 = function _templateObject6() {
        return data;
    };
    return data;
}
function _templateObject7() {
    var data = _tagged_template_literal([
        "\n  display: flex;\n  align-items: center;\n\n  img {\n    width: 32px !important;\n    height: 32px !important;\n    max-width: 32px;\n  }\n"
    ]);
    _templateObject7 = function _templateObject7() {
        return data;
    };
    return data;
}
import { breakpoints, pxToCssFont } from "@vfit/shared/themes";
import styled from "styled-components";
export var StickyWhatsappModalContainer = styled.div.withConfig({
    componentId: "sc-faca17e8-0"
})(_templateObject());
export var ContentLeft = styled.div.withConfig({
    componentId: "sc-faca17e8-1"
})(_templateObject1(), breakpoints.desktop);
export var ContentRight = styled.div.withConfig({
    componentId: "sc-faca17e8-2"
})(_templateObject2());
export var ModalTitle = styled.div.withConfig({
    componentId: "sc-faca17e8-3"
})(_templateObject3(), pxToCssFont(18, 16), breakpoints.desktop, pxToCssFont(36, 45));
export var ModalDescription = styled.div.withConfig({
    componentId: "sc-faca17e8-4"
})(_templateObject4());
export var ImageContainer = styled.div.withConfig({
    componentId: "sc-faca17e8-5"
})(_templateObject5());
export var ModalSupport = styled.div.withConfig({
    componentId: "sc-faca17e8-6"
})(_templateObject6(), breakpoints.desktop);
export var IconWrapper = styled.div.withConfig({
    componentId: "sc-faca17e8-7"
})(_templateObject7());
