/* eslint-disable import/export */ import { HttpClient } from "./client";
export { headersNext, mode, credentials, prefixUrl } from "./config";
export var nextClient = new HttpClient("NEXT");
export var cmsClient = new HttpClient("CMS");
export var dxlClient = new HttpClient("DXL");
export var dxlClientJourneyApp = new HttpClient("DXL_JOURNEY_APP");
export var serverlessClient = new HttpClient("WEBAPI_SERVERLESS");
export var awsConsumerClient = new HttpClient("AWSAPI_CONSUMER");
export var dxlConsumerCloudClient = new HttpClient("DXLCLOUD_CONSUMER");
export var dxlGatewayCloud = new HttpClient("DXL_GATEWAY_CLOUD");
export default HttpClient;
export * from "./client";
export * from "./model";
