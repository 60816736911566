import { LoggerInstance } from "../logger";
var fallBackTemp = {
    NODE_ENV: "",
    NX_GOOGLE_MAPS_API_KEY: "",
    NX_LOGGER_LEVEL: "",
    NX_MEDIA_ROOT: "",
    PROD: "",
    UAT1: "",
    UAT2: "",
    UAT6: "",
    UAT7: "ALL",
    UAT8: "",
    UAT9: "",
    AEM_BUSINESS_ENVIRO: "",
    AMPLIFY_DIFF_DEPLOY: "",
    AMPLIFY_MONOREPO_APP_ROOT: "",
    NX_CMS: "https://web-bs.vodafone.it/webconsumer",
    NX_FOLDER_MEDIA: "",
    NX_NEXT_ENVIRO: "",
    NX_DXL_PREFIX: "",
    NX_TEST_QA: "false",
    IS_DEV: "false",
    NX_YEXT_API_KEY: "",
    NX_YEXT_BUSINESS_ID: "",
    NX_YEXT_EXP_KEY: "",
    NX_YEXT_EXP_VER: "",
    NX_YEXT_REDIRECT_URL: "",
    NX_APP_DYNAMICS_API_KEY: "",
    NX_WEBAPI_SERVERLESS: "",
    NX_APP_TYPE: "",
    NX_UAT_TESTING: "",
    NX_DXL_APP_PREFIX: "",
    NX_DXL_BS_APP_PREFIX: "",
    NX_DXL_CONSUMER_CLOUD: "",
    NX_UTAG_BASE_URL: "",
    NX_DXL_VBI_PREFIX: "",
    NX_RESHAPING_RELEASE_URL: "",
    NX_AWSAPI_CONSUMER_PREFIX: "",
    NX_CMS_CONTRACT_DOWNLOAD: "https://myvfapp-aem.vodafone.it/",
    NX_VR_URL: "",
    NX_ENV: "",
    NX_SHOW_APPOINTMENT: "",
    NX_GS_APP_URL: "",
    NX_DXL_VBI_CLOUD_PREFIX: "",
    NX_MOCK_API_KEY: "",
    NX_MOCK_API_KEY_ENABLED: "",
    NX_DXL_GATEWAY_CLOUD: "",
    NX_MOCK_APP: "",
    NX_MOCK_WINBACK: "",
    NX_CMS_APP: "https://myvfapp-aem-bs.vodafone.it",
    NX_CMS_APP_CHANNEL: "web",
    NX_SHOW_SPEED_UP: "false",
    NX_TECH_APPOINTMENT_REFETCH_CREATE_SLOT: "false",
    NX_NEW_ABROAD: "FALSE",
    NX_MEDIA_QUERY_DEFAULT_STATE: ""
};
export var handleEnv = function(env) {
    var ref, ref1;
    if ((ref = process.env) === null || ref === void 0 ? void 0 : ref[env]) return process.env[env];
    LoggerInstance.debug("PROCESS.ENV.".concat(env, " IS: ").concat((ref1 = process.env) === null || ref1 === void 0 ? void 0 : ref1[env]));
    return fallBackTemp[env] || false;
};
export default handleEnv;
